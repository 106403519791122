import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert, DropdownButton, Dropdown, Button } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/gold3.css'
import '../style/sty.scss'
import moment from 'moment'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import getData from '../params/getdata'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { css } from 'glamor'

let abu = "#909090"
let cmain = '#CA9228'
let black = 'rgb(38,38,38)'

let id = 'lusi-syahmi'
let inisial_co = 'Syahmi'
let inisial_ce = 'Lusi'
let lengkap_co = 'Muhammad Syahmi Bin Supoano'
let lengkap_ce = 'Fransiska Lusia Kastina'
let bapak_co = 'Bpk. HJ. Supoano Bin Salleh Khan'
let ibu_co = 'Ibu HJH. Mazlah Bte Mohd Yusoff'
let bapak_ce = "Bpk. Rafael Marung Tagung (Alm) "
let ibu_ce = "Ibu Yosephin Puji Rahayu"
let ig_co = "syahmisupoano"
let ig_ce = "lusiakastina"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/30/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/UVNfUx5zNBB8nfhj8"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MzVmNHVxcTdhcXQzMm40anNhaHNkODgzaTQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com  '
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.014665818753!2d106.68364011476928!3d-6.261797895467586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb9e836f533b%3A0x5bfb92326eb55f21!2sRumah%20Bapak!5e0!3m2!1sen!2sid!4v1609845976120!5m2!1sen!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
gmaps1 = gmaps1.split('"')[1]


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            friend: 'Alone',
            comment: []
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment = await getData(`kepada:"${id}"`)


            this.setState({ comment: comment.reverse() })
            console.log(comment)
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "${this.state.friend}", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    dropdown = () => {
        let { friend } = this.state
        let item = []
        // for (let index = 1; index < 3; index++) {
        //     item[index] = (<Dropdown.Item onClick={() => {
        //         this.setState({ friend: index })
        //     }}>{index}</Dropdown.Item>)
        // }
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'Alone' })
        }}>Alone</Dropdown.Item>)
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'Partner' })
        }}>Partner</Dropdown.Item>)
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px', color: '#86835d' }} className="mb-0 w-100 text-center">
                        How will you attend
          </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }
    render() {
        let font = 'taken_by_vultures_demoregular'
        let fontsub = 'Josefin Sans'
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, comment } = this.state
        let slider = []
        let slide = [
            "1 (1).jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "ZEQ_2573 upload.jpg",
        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.001&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Allura&display=swap" rel="stylesheet"></link>
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `none`,
                    backgroundColor: 'white',
                    backgroundSize: 'cover', backgroundPosition: 'center',

                }}>

                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: `url('${modal}')`, zIndex: 1,
                            backgroundColor: '#edede3'
                        }}>
                            <Item>
                                <Col xs={8} md={4} className='pt-5'>
                                    <img className='img-fluid w-100' style={{ marginTop: '-50px' }}
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        <br />  {query ? <>Kepada Yth : {query}</> : ''} <br /></h2>
                                }</Item>
                            <Item>
                                <Row className=' position-absolute' style={{ bottom: '10%' }} >
                                    <Item>
                                        <Button onClick={() => { this.play() }}
                                            style={{ backgroundColor: 'transparent', borderColor: 'white', color: 'white' }}
                                        >
                                            Open Invitation
                            </Button >
                                    </Item>
                                </Row>
                            </Item>
                        </Container>
                        <div className={hide ? 'd-none' : 'd-block'} style={{ zIndex: -1 }}>
                            <div id="top"
                                style={{ position: 'relative', backgroundColor: '#edede3' }}
                            >
                                <Col md={2} xs={4} className="p-0 position-fixed" style={{ top: 0, left: 0, zIndex: '0' }}>
                                    <img src={pw(id, "atas-kiri.png")} className="w-100 img-fluid" />
                                </Col>
                                <Col md={2} xs={4} className="p-0 position-fixed" style={{ top: 0, right: 0, zIndex: '0' }}>
                                    <img src={pw(id, "atas-kanan.png")} className="w-100 img-fluid" />
                                </Col>
                                <Col md={2} xs={4} className="p-0 position-fixed" style={{ bottom: 0, right: 0, zIndex: '0' }}>
                                    <img src={pw(id, "bawah-kanan.png")} className="w-100 img-fluid" />
                                </Col>
                                <Col md={2} xs={4} className="p-0 position-fixed" style={{ left: 0, bottom: 0, zIndex: '0' }}>
                                    <img src={pw(id, "kiri-bawah.png")} className="w-100 img-fluid" />
                                </Col>
                                <div className="p-0 position-fixed" style={{
                                    left: '5vw', top: '5vh', zIndex: '0', height: '90vh', border: `2px solid ${cmain}`,
                                    width: '90vw'
                                }}>

                                </div>


                                <div id="lusi" style={{ position: 'relative' }}>
                                    {/* <Container className="py-3 ">
                                        <Item>
                                            <h1 className="w-100 text-center" style={{ color: cmain, fontFamily: font }} >
                                                We found love
                                        </h1>

                                            <p className="text-center px-3" style={{ color: cmain, fontFamily: fontsub }}>
                                                “Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ” <br /><br />(Ar-Rum: 21)
                                            </p>
                                        </Item>



                                        <Item>
                                            <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala, kami mengundang<br/>
                                            Bapak/ Ibu / Saudara/i, untuk menghadiri Resepsi Pernikahan kami. Dengan tetap mematuhi protokoler kesehatan
                                            </p>
                                        </Item>
                                    </Container> */}
                                    <Container className="py-5" >

                                        <div style={{ backgroundColor: 'rgba(255,255,255,0.3)', borderRadius: '10px' }} className="p-3">
                                            <p style={{ fontFamily: "Tinos,serif" }}>
                                                <i><b>Bismillahirrahmanirrahim</b><br />
                                            Assalamualaikum Warahmatullahi Wabarakatuh
                                            </i>
                                            </p>
                                            <p style={{ fontSize: '12px' }}>
                                                IN THE NAME OF ALLAH, MOST GRACIOUS MOST COMPASSIONATE
                                        </p>
                                            <p>
                                                -----------------------------------------------
                                        </p>
                                            <p style={{ fontSize: '14px' }}>
                                                HJ Supoano Bin Salleh Khan & HJH Mazlah Bte Mohd Yusoff
                                        </p>
                                            <p style={{ fontSize: '12px' }}>
                                                request the pleasure of your company at the solemnization reception dinner of their son

                                        </p>
                                            <p style={{ fontFamily: 'Allura,cursive', fontStyle: '', fontSize: '32px' }}>

                                                Muhammad Syahmi
                                                    <br />Bin Supoano<br />
                                                to<br />
                                                Fransiska Lusia <br />Kastina

                                            </p>

                                            <p style={{ fontSize: '14px' }}>
                                                Daughter of Mr. Rafael Marung Tagung (Alm) & Mrs. Yosephin Puji Rahayu
                                          </p>
                                            <p>
                                                <b>
                                                    Friday, 5 February 2021<br /><br />

                                                    Solemnization
                                                </b><br />
                                                06:00 pm<br />
                                                <br />
                                                <b>
                                                    Reception Dinner
                                                </b><br />
                                                07:00 pm<br /><br /><br />
                                                <b> Istana II Ballroom (Ground Floor)</b><br />
Tanjong Katong Complex<br />
845 Geylang Rd. Singapore 400845


                                            </p>
                                            <Item>
                                                <Button className="m-1" style={{ backgroundColor: '#D0B577', borderRadius: '0', borderColor: 'white' }}>
                                                    <p className="mb-0" style={{ color: 'white' }}
                                                        onClick={() => { window.location.href = 'https://www.google.com/maps/place/Tanjong+katong+Complex/@1.3163227,103.8946538,18.08z/data=!4m5!3m4!1s0x0:0x808bff2826df5e4d!8m2!3d1.3159363!4d103.8945531' }}
                                                    >
                                                        Get Direction
                                                   </p>
                                                </Button>
                                            </Item>
                                            <div style={{ marginTop: '-50px' }}>
                                                <Timer cmain={cmain} waktunikah={waktunikah} />
                                            </div>
                                            <Item>
                                                <Button className="m-1" style={{ backgroundColor: '#D0B577', borderRadius: '0', borderColor: 'white' }}>
                                                    <p className="mb-0" style={{ color: 'white' }}
                                                        onClick={() => { window.location.href = 'https://www.google.com/maps/place/Tanjong+katong+Complex/@1.3163227,103.8946538,18.08z/data=!4m5!3m4!1s0x0:0x808bff2826df5e4d!8m2!3d1.3159363!4d103.8945531' }}
                                                    >
                                                        Add to Google Calendar
                                                   </p>
                                                </Button>
                                            </Item>


                                        </div>

                                    </Container>

                                    <Container id='sectiongold58' className="py-3" >



                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: 'Tinos,serif',
                                                        color: cmain
                                                    }}>
                                                        RSVP
                                                     </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                                                        <p>
                                                            Will you be attending ?
                                                        </p>
                                                        <Item>
                                                            <div id="formradio" style={{ marginTop: '-50px' }}>
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input
                                                                            type="radio"
                                                                            id="featured-1"
                                                                            name="featured"
                                                                            checked={hadir ? true : false} />
                                                                        <label for="featured-1">Yes</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"
                                                                        >No</label>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            {
                                                                this.dropdown()
                                                            }
                                                        </Item>
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Send </div>
                                                        </Item>
                                                        <p>
                                                            Notes:<br />
                                                                            *due to covid regulation, invitation only valid for 2 person
                                                        </p>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>

                                    </Container>




                                    <Container className="text-center dinny px-5">
                                        <Item>
                                            <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                                                <h1 className="fs30"
                                                    style={{


                                                        color: cmain
                                                    }}>
                                                    Covid-19
                          </h1>
                                                <Item>
                                                    <Col xs={12}>
                                                        <p className="cblack text-center w-100 fs16">
                                                            It’s important to us that everyone stays safe, please read carefully our Wedding Day Covid-19 Guidance before attending the event:
                                                    </p>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw('asset', 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                All participants must carry and wear a face mask during the wedding event
                        </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw('asset', 'salaman.png')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                Avoid handshake and use Namaste as an alternative to greet other guest.
                      </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw('asset', 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                Observe and keep a distance of 2 meter from other
                      </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw("reni-shidqi", 'hand.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                Bring your own hand sanitizer and use it before and after make a physical contact.
                      </p>
                                                        </Row>
                                                    </Col>

                                                </Item>
                                            </Col>
                                        </Item>

                                        <Item>
                                            <Col>
                                            </Col>

                                        </Item>

                                    </Container>
                                    <Container id='sectiongold56'>
                                        <div className='pt-3'>

                                            <div data-aos={`fade-right`} data-aos-duration="2000">
                                                <Item>
                                                    <div className='kotak col-10' style={{ backgroundColor: 'white' }}>
                                                        <Item>

                                                            <p className='text-center p-2 px-4 fs14' style={{ color: cmain }}>
                                                                <b>Live Moment </b><br />
Help us capture the best moment in our wedding day with the following hashtag in your social media post: #


</p>
                                                        </Item>
                                                    </div>

                                                </Item>
                                            </div>
                                        </div>
                                    </Container>


                                    <Container className='mt-3 pb-5 pt-3' data-aos="fade-right"
                                        data-aos-duration="1000"
                                        style={{ backgroundColor: 'white' }}
                                    >

                                        <p className="">
                                            <b>Live Streaming</b><br /><br />
Given the pandemic condition, we are pleased to invite all of our family and friends who couldn’t attend the wedding to join our live streaming

                                    </p>
                                        <Item>
                                            <Button className="m-1" style={{ backgroundColor: '#D0B577', borderRadius: '0', borderColor: 'white' }}
                                            
                                            onClick={() => { window.location.href = 'https://youtu.be/ULSzCSr8Bw0' }}
                                            >
                                                <p className="mb-0" style={{ color: 'white' }}
                                                >
                                                    Live Streaming
                                                   </p>
                                            </Button>
                                        </Item>
                                        <Slider slide={slider} />

                                    </Container>
                                    <p style={{ color: 'white' }} className="pt-3 px-5">
                                        Messages from our family & friend who submitted in the website<br /><br />
                                        <Container>
                                            <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                                {comment.map((v, i) => {
                                                    console.log(v)
                                                    return (
                                                        <Row className="w-100 text-center mx-auto">
                                                            <Col></Col>
                                                            <Col xs={12} md={6} className="py-2">
                                                                <p className="w-100 text-left my-auto"
                                                                    style={{ fontSize: '18px', color: 'white' }}>
                                                                    <b>{decodeURI(v.dari)}</b><br />
                                                                    {decodeURI(v.pesan)}<br />
                                                                    <span style={{ fontSize: '14px' }}>
                                                                        {moment(v.createdAt).startOf('hour').fromNow()}
                                                                    </span>
                                                                </p>
                                                            </Col>
                                                            <Col></Col>
                                                        </Row>
                                                    )
                                                })
                                                }
                                            </Row>
                                        </Container>
Your presence and kind blessing will be highly appreciated<br />
                                        <b>#SyahmiGotLusi </b>

                                        <br />
                                    </p>


                                    <Foot ig={pw("asset", "logoig-black.svg")} dark />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

